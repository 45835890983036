import React from "react";
import { graphql } from "gatsby";

import Seo from "../components/seo";
import { LocaleContext } from "../components/layout";
import useTranslations from "../components/useTranslations";

export default function Blog({ data }) {
  const { locale, url } = React.useContext(LocaleContext);
  const { blog } = useTranslations();

  return (
    <>
      <Seo
        url={url}
        lang={locale}
        title={blog}
        description={data.markdownRemark.frontmatter.metaDescription}
        bodyClass="page-blog"
      />
      <div className="container">
        <iframe
          className="padlet"
          src="https://padlet.com/embed/oyb1jbwht2nwimmx"
          title="Offener Prozess Padlet"
          frameBorder="0"
          allow="camera;microphone;geolocation"
        ></iframe>
      </div>
    </>
  );
}

export const query = graphql`
  query ($locale: String!) {
    markdownRemark(
      frontmatter: { template: { eq: "about" } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        metaDescription
      }
    }
  }
`;
